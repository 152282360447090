<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ taxesGroup.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_TAXES_GROUPS)"
            @click="deleteTaxesGroup"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_TAXES_GROUPS)"
            @click="editTaxesGroup"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="taxesGroup.name">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ taxesGroup.name ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="taxesGroup.excerpt">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            {{ taxesGroup.excerpt ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $formatDate(taxesGroup.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $formatDate(taxesGroup.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "taxes-group-view-global",

  props: ["taxesGroup"],

  data() {
    return {};
  },

  created() {},

  methods: {
    taxesGroupUpdated() {
      this.$emit("taxesGroupUpdated", true);
    },
    editTaxesGroup() {
      this.$emit("onEditTaxesGroup", this.taxesGroup);
    },
    deleteTaxesGroup() {
      this.$emit("onDeleteTaxesGroup", this.taxesGroup);
    },
  },

  mounted() {},

  watch: {
    taxesGroup(taxesGroup) {},
  },
};
</script>
