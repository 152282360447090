<template>
  <div class="elite-tabs-wrapper-content">
    <div :class="'title-header '+(isOut?'out':'in')">
      <div class="row">
        <div class="flex justify-center col-6">
          <in-out-badge class="float-left mr-3" :type="isOut?'OUT':'IN'" />
          <purchases-invoice v-if="isOut" :purchasesInvoice="transaction.invoice" />
          <sales-invoice v-else :salesInvoice="transaction.invoice" />
        </div>

        <div class="col-6 text-right">
          <base-button
            type="button"
            size="sm"
            @click="toggleOnHold(transaction)"
            class="elite-button add"
            v-if="transaction.status != PAYMENT_STATUS_COMPLETED && (transaction.status  == PAYMENT_STATUS_PENDING || transaction.status  == PAYMENT_STATUS_HOLDON  )"
          >
          <div v-if="transaction.status == PAYMENT_STATUS_PENDING">
            <span class="btn-inner--icon">
              <i class="far fa-stop"></i>
            </span>
            <span class="btn-inner--text">
              {{$t("PAYMENTS.PUT_ON_HOLD")}}
            </span>
          </div>
          <!-- <div v-if="transaction.status == PAYMENT_STATUS_HOLDON"> -->
          <div v-else>
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{$t("PAYMENTS.PUT_ON_PENDING")}}
            </span>
          </div>
          </base-button>
          <base-button
            type="button"
            size="sm"
            @click="validateTransaction(transaction)"
            class="elite-button add"
            v-if="transaction.status != PAYMENT_STATUS_COMPLETED"
          >
            <span class="btn-inner--icon">
              <i class="far fa-check"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.VALIDATE") }}
            </span>
          </base-button>
          <base-button
            type="button"
            size="sm"
            @click="cancelTransaction(transaction)"
            class="elite-button add"
          >
            <span class="btn-inner--icon">
              <i class="far fa-times"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.CANCEL") }}
            </span>
          </base-button>
          <base-button
            class="btn-outline-danger elite-button add"
            icon
            size="sm"
            v-if="
              transaction.status == PAYMENT_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_PURCHASES_PAYMENTS)
            "
            @click="deleteTransaction"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.DATE") }}</dt>
          <dd>
            {{ $formatDate(transaction.date) }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
        >
          <dt>{{ $t("COMMON.INVOICE") }}</dt>
          <dd>
            <object-link :object="transaction.invoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATION") }}</dt>
          <dd>
            <location v-if="isOut" :purchasesInvoice="transaction.invoice.id?.toString()" />
            <location v-else :salesInvoice="transaction.invoice.id?.toString()" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="transaction.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <status-badge
              :data="transaction"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.AMOUNT") }}</dt>
          <dd>
            <amount-badge :type="isOut?'OUT':'IN'" :amount="transaction.amount" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PURCHASES_PAYMENTS.SOURCE") }}</dt>
          <dd>
            {{ $t(`PAYMENTS.${transaction.source}`) }}
          </dd>
        </dl>

        <dl class="row" v-if="transaction.excerpt">
          <dt>
            {{ $t("COMMON.EXCERPT") }}
          </dt>
          <dd>
            <div v-html="transaction.excerpt"></div>
          </dd>
        </dl>

        <dl class="row" v-if="transaction.transaction_id">
          <dt>
            {{ $t("PURCHASES_PAYMENTS.TRANSACTION_ID") }}
          </dt>
          <dd>
            {{ transaction.transaction_id }}
          </dd>
        </dl>

        <dl class="row" v-if="transaction.transaction_data">
          <dt>
            {{ $t("PURCHASES_PAYMENTS.TRANSACTION_DATA") }}
          </dt>
          <dd>
            {{ transaction.transaction_data }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="transaction.created_at">
            {{ $formatDate(transaction.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="transaction.updated_at">
            {{ $formatDate(transaction.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "../components/StatusBadge.vue";
import {
  PAYMENT_STATUS_DRAFT,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_STATUS_HOLDON
} from "@/constants/payments";
import Location from "@/components/Location";
import PurchasesInvoice from "@/components/PurchasesInvoice";
import SalesInvoice from "@/components/SalesInvoice";
import InOutBadge from "../components/atoms/InOutBadge.vue";
import AmountBadge from "../components/atoms/AmountBadge.vue";
export default {
  name: "transaction-view-global",

  components: { StatusBadge, Location, PurchasesInvoice, SalesInvoice, InOutBadge, AmountBadge },

  props: ["transaction"],

  data() {
    return {
      PAYMENT_STATUS_DRAFT: PAYMENT_STATUS_DRAFT,
      PAYMENT_STATUS_PENDING: PAYMENT_STATUS_PENDING,
      PAYMENT_STATUS_HOLDON:PAYMENT_STATUS_HOLDON,
      PAYMENT_STATUS_COMPLETED: PAYMENT_STATUS_COMPLETED,
    };
  },

  computed: {
    isOut() {
      return this.transaction.type=='purchases-payments';
    },
  },

  created() {},

  methods: {
    validateTransaction() {
      this.$emit("onValidateTransaction", this.transaction);
    },
    cancelTransaction() {
      this.$emit("onCancelTransaction", this.transaction);
    },
    editTransaction() {
      this.$emit("onEditTransaction", this.transaction);
    },
    deleteTransaction() {
      this.$emit("onDeleteTransaction", this.transaction);
    },
    toggleOnHold() {
      this.$emit("onToggleOnHold", [this.transaction]);
    },
    sendPaymentsToDpa() {
      this.$emit("onSendPaymentsToDpa", [this.transaction]);
    },
  },

  mounted() {},

  watch: {
    transaction(transaction) {},
  },
};
</script>
