<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3> <span class="text-uppercase text-gray">{{ $t("CHARGES." + charge?.category) }} - </span> {{ charge.name }}</h3>
          <badge v-if="charge.is_active" type="success">
            {{ $t("CHARGES.ACTIVE") }}
          </badge>
          <badge v-else type="secondary">
            {{ $t("CHARGES.DEACTIVATED") }}
          </badge>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_CHARGES)"
            @click="deleteCharge"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_CHARGES)"
            @click="editCharge"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
          <div class="row mb-3 mt-2">
            <el-switch
              :disabled="charge.is_default"
              @change="handleActivateOrDeactivate"
              style="display: block"
              v-model="chargeIsActive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="$t('CHARGES.ACTIVE')"
              :inactive-text="$t('CHARGES.DEACTIVATED')"
            >
            </el-switch>
          </div>
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("CHARGES.CATEGORY") }}</dt>
          <dd class="text-uppercase" v-if="charge?.category">
            {{ $t("CHARGES." + charge?.category) }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.name">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ charge.name ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.label">
          <dt>{{ $t("CHARGES.DENOMINATION") }}</dt>
          <dd>
            {{ charge.label ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.charge_number">
          <dt>{{ $t("CHARGES.TAXE_NUMBER") }}</dt>
          <dd>
            {{ charge.charge_number ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.transaction_type">
          <dt>{{ $t("CHARGES.TRANSACTION") }}</dt>
          <dd>
           {{ $t("CHARGES." + charge?.transaction_type) }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.calculation_base">
          <dt>{{ $t("CHARGES.CALCULATION_BASE") }}</dt>
          <dd>
            {{ $t("CHARGES." + charge?.calculation_base) }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.amount">
          <dt>{{ $t("CHARGES.AMOUNT") }}</dt>
          <dd>
            {{ $formatCurrency(charge.amount) }}
          </dd>
        </dl>

        <dl class="row" v-if="charge.percentage">
          <dt>{{ $t("CHARGES.PERCENTAGE") }}</dt>
          <dd>
            {{ charge.percentage  }} %
          </dd>
        </dl>

        <dl class="row" v-if="charge.excerpt">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            {{ charge.excerpt ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $formatDate(charge.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $formatDate(charge.updated_at) }}
          </dd>
        </dl>
      </div>

      <div class="paliers my-1" v-if="charge.tiers && charge.tiers.length">
        <tiers-list
          :tiers="charge.tiers"
          :title="$t('CHARGES.PALIERS')"
          currency="$"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Switch } from "element-ui";
import TiersList from "../components/TiersList.vue";
export default {
  name: "charge-view-global",
  components: {
    TiersList,
    [Switch.name]: Switch,
  },
  props: ["charge"],

  data() {
    return {
      chargeIsActive: this.charge.is_active,
    };
  },

  created() {},

  methods: {
    activateOrDeactivate() {
      this.$emit("onActivateOrDeactivate", this.charge);
    },
    async handleActivateOrDeactivate() {
      await this.activateOrDeactivate();
      this.chargeIsActive = this.charge.is_active;
    },
    chargeUpdated() {
      this.$emit("chargeUpdated", true);
    },
    editCharge() {
      this.$emit("onEditCharge", this.charge);
    },
    deleteCharge() {
      this.$emit("onDeleteCharge", this.charge);
    },
  },

  mounted() {
    this.chargeIsActive = this.charge.is_active;
  },

  watch: {
    charge(charge) {
      this.chargeIsActive = charge.is_active;
    },
  },
};
</script>
