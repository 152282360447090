<template>
  <div class="pm-form vehicle-form">
    <div class="steps-header">
      <el-steps :active="activeStep" finish-status="success">
        <el-step :title="$t('PAYMENT_METHODS.PAYMENT_METHOD')" />
        <el-step :title="$t('SIGNATURES.SIGNATURE')" />
      </el-steps>
    </div>
    <div v-if="!openContractForm" class="paymentmethod-form">
      <el-form
        class="add-form payment-method-form"
        @submit.prevent="() => {}"
        @keydown.enter.prevent="() => {}"
        :model="paymentMethod"
        label-position="top"
        ref="paymentMethodForm"
      >
        <div class="form-group-wrapper overflow-hidden">
          <el-form-item
            v-if="holderId == null"
            class="w-100"
            prop="holder_id"
            :rules="[
              {
                required: true,
                message: this.$t('PAYMENT_METHODS.holder_required'),
              },
            ]"
          >
            <div class="account-owner w-100">
              <div class="row w-100">
                <div class="col-lg-6">
                  <base-input
                    v-if="
                      $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
                    "
                    :label="$t('COMMON.ORGANIZATION')"
                    :placeholder="$t('COMMON.ORGANIZATION')"
                  >
                    <organization-selector
                      :filterable="true"
                      :showAll="false"
                      :allowNone="true"
                      @organizationChanged="
                        (organizationId) => {
                          this.selectedOrganizationId = organizationId;
                          onOrganizationChanged(organizationId);
                        }
                      "
                    />
                  </base-input>
                </div>
                <div class="col-lg-6">
                  <base-input
                    v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
                    :placeholder="`${$t('COMMON.LOCATION')}`"
                    :label="$t('COMMON.LOCATION')"
                  >
                    <location-selector
                      :filterable="true"
                      :showAll="false"
                      :allowNone="true"
                      :organization="selectedOrganizationId"
                      @locationChanged="
                        (locationId) => {
                          this.selectedLocationId = locationId;
                          onLocationChanged(locationId);
                        }
                      "
                    />
                  </base-input>
                </div>
              </div>
            </div>
          </el-form-item>

          <div class="account mb-3">
            <h3 class="text-danger">
              {{ $t("PAYMENT_METHODS.PAYMENT_METHOD_ACCOUNT") }}
            </h3>
            <el-divider />
            <div class="row">
              <div class="col-lg-6">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.bank_name')"
                  prop="details.bank_name"
                  :rules="[
                    {
                      required: true,
                      message: this.$t('PAYMENT_METHODS.bank_name_required'),
                    },
                  ]"
                >
                  <el-input
                    v-model="paymentMethod.details.bank_name"
                    :placeholder="$t('PAYMENT_METHODS.bank_name')"
                  />
                </el-form-item>
              </div>
              <div class="col-lg-6">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.account_number')"
                  prop="details.account_number"
                  :rules="[
                    {
                      required: true,
                      message: this.$t(
                        'PAYMENT_METHODS.account_number_required'
                      ),
                    },
                  ]"
                >
                  <el-input
                    type="number"
                    v-model="paymentMethod.details.account_number"
                    :placeholder="$t('PAYMENT_METHODS.account_number')"
                  />
                </el-form-item>
              </div>
              <div class="col-lg-3">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.transit_number')"
                  prop="details.transit_number"
                  :rules="[
                    {
                      required: true,
                      message: this.$t(
                        'PAYMENT_METHODS.transit_number_required'
                      ),
                    },
                  ]"
                >
                  <el-input
                    type="number"
                    v-model="paymentMethod.details.transit_number"
                    :placeholder="$t('PAYMENT_METHODS.transit_number')"
                  />
                </el-form-item>
              </div>
              <div class="col-lg-3">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.branch_code')"
                  prop="details.branch_code"
                  :rules="[
                    {
                      required: true,
                      message: this.$t('PAYMENT_METHODS.branch_code_required'),
                    },
                  ]"
                >
                  <el-input
                    v-model="paymentMethod.details.branch_code"
                    :placeholder="$t('PAYMENT_METHODS.branch_code')"
                  />
                </el-form-item>
              </div>
              <div class="col-lg-6">
                <el-form-item :label="$t('PAYMENT_METHODS.BRANCH_ADDRESS')">
                  <addresses-selector
                    unique-id="bank-address-input"
                    ref="bank_address"
                    key="bank_address"
                    :address="paymentMethod.details.bank_address"
                    @addressInputChangeChanged="
                      (values) => {
                        paymentMethod.details.bank_address =
                          values.formatted_address ?? '';
                      }
                    "
                  />
                </el-form-item>
              </div>
            </div>
          </div>

          <div class="holder mb-3">
            <h3 class="text-danger">
              {{ $t("PAYMENT_METHODS.PAYMENT_METHOD_HOLDER") }}
            </h3>
            <el-divider />
            <div class="row">
              <!-- <div class="col-lg-6">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.firstname')"
                  prop="details.holder.firstname"
                  :rules="[
                    {
                      required: true,
                      message: this.$t('PAYMENT_METHODS.firstname_required'),
                    },
                  ]"
                >
                  <el-input
                    v-model="paymentMethod.details.holder.firstname"
                    :placeholder="$t('PAYMENT_METHODS.firstname')"
                  />
                </el-form-item>
              </div> -->
              <div class="col-lg-6">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.PAYMENT_METHOD_HOLDER_NAME')"
                  prop="details.holder.name"
                  :rules="[
                    {
                      required: true,
                      message: this.$t(
                        'PAYMENT_METHODS.PAYMENT_METHOD_HOLDER_NAME_REQUIERD'
                      ),
                    },
                  ]"
                >
                  <el-input
                    v-model="paymentMethod.details.holder.name"
                    :placeholder="
                      $t('PAYMENT_METHODS.PAYMENT_METHOD_HOLDER_NAME')
                    "
                  />
                </el-form-item>
              </div>

              <div class="col-lg-6">
                <el-form-item
                  :label="$t('PAYMENT_METHODS.email')"
                  prop="details.holder.email"
                  :rules="[
                    {
                      required: true,
                      message: $t('PAYMENT_METHODS.email_required'),
                    },
                    {
                      type: 'email',
                      message: $t('PAYMENT_METHODS.email_correct'),
                    },
                  ]"
                >
                  <el-input
                    type="email"
                    v-model="paymentMethod.details.holder.email"
                    :placeholder="$t('PAYMENT_METHODS.email')"
                  />
                </el-form-item>
              </div>
              <div class="col-lg-6">
                <el-form-item :label="$t('PAYMENT_METHODS.phone')">
                  <phone-number-input
                    :required="false"
                    :phoneNumber="paymentMethod.details.holder.phone"
                    @phoneNumberChanged="
                      (phone) => {
                        paymentMethod.details.holder.phone = phone;
                      }
                    "
                  />
                </el-form-item>
              </div>
              <div class="col-lg-6">
                <el-form-item :label="$t('PAYMENT_METHODS.address')">
                  <addresses-selector
                    unique-id="account-holder-address-input"
                    ref="holder_address"
                    key="holder_address"
                    :address="paymentMethod.details.holder.address"
                    @addressInputChangeChanged="
                      (addresses) => {
                        paymentMethod.details.holder.address =
                          addresses.formatted_address ?? '';
                      }
                    "
                  />
                  <!-- <el-input
                    v-model="paymentMethod.details.holder.address"
                    :placeholder="$t('PAYMENT_METHODS.address')"
                  /> -->
                </el-form-item>
              </div>
            </div>
          </div>

          <!-- Section Signataires -->
          <div class="signers w-100 mb-3">
            <div class="row">
              <div class="col-md-4 text-start">
                <h3 class="text-danger">
                  {{ $t("PAYMENT_METHODS.PAYMENT_METHOD_SIGNERS") }}
                </h3>
              </div>
              <div class="col-md-8 d-flex justify-content-end">
                <!-- Bouton pour ajouter un signataire -->
                <el-button
                  class="my-3"
                  type="secondary"
                  icon="el-icon-plus"
                  @click="addSigner"
                  style="margin-top: 10px"
                >
                  {{ $t("PAYMENT_METHODS.ADD_SIGNER") }}
                </el-button>
              </div>
              <el-divider />
            </div>

            <div
              class="add-signer mb-3"
              v-for="(signer, index) in paymentMethod.signers"
              :key="index"
            >
              <div class="delete-signer d-flex justify-content-end">
                <el-button
                  v-if="index != 0"
                  type="danger"
                  class="very-small"
                  icon="el-icon-delete"
                  circle
                  @click="removeSigner(index)"
                  >x</el-button
                >
              </div>
              <div class="row">
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('PAYMENT_METHODS.firstname')"
                    :prop="'signers.' + index + '.firstname'"
                    :rules="[
                      {
                        required: true,
                        message: $t('PAYMENT_METHODS.firstname_required'),
                      },
                    ]"
                  >
                    <el-input
                      v-model="signer.firstname"
                      :placeholder="$t('PAYMENT_METHODS.firstname')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('PAYMENT_METHODS.lastname')"
                    :prop="'signers.' + index + '.lastname'"
                    :rules="[
                      {
                        required: true,
                        message: $t('PAYMENT_METHODS.lastname_required'),
                      },
                    ]"
                  >
                    <el-input
                      v-model="signer.lastname"
                      :placeholder="$t('PAYMENT_METHODS.lastname')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('PAYMENT_METHODS.email')"
                    :prop="'signers.' + index + '.email'"
                    :rules="[
                      {
                        required: true,
                        message: $t('PAYMENT_METHODS.email_required'),
                      },
                      {
                        type: 'email',
                        message: $t('PAYMENT_METHODS.email_correct'),
                      },
                    ]"
                  >
                    <el-input
                      type="email"
                      v-model="signer.email"
                      :placeholder="$t('PAYMENT_METHODS.email')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :prop="'signers.' + index + '.address'"
                    :label="$t('PAYMENT_METHODS.address')"
                  >
                    <addresses-selector
                      :unique-id="'signer-address-input-' + index"
                      :ref="'signer_address_' + index"
                      :key="'signer_address_' + index"
                      :address="signer.address"
                      @addressInputChangeChanged="
                        (values) => {
                          signer.address = values.formatted_address ?? '';
                        }
                      "
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div class="steps-footer">
        <el-button
          type="primary"
          @click="goToContract"
          :loading="loading"
          class="next"
        >
          {{ $t("COMMON.NEXT") }}
          <i class="fal fa-arrow-right"></i>
        </el-button>
      </div>
    </div>
    <div v-else class="w-100 contract-form">
      <div class="payment-method-signers mt-3">
        <h4 class="text-danger mb-3">{{ $t("PAYMENT_METHODS.SIGNERS") }}</h4>
        <object-details
          v-if="paymentMethod.signers.length"
          :data="getSigners(paymentMethod.signers)"
          :with-trad="false"
        />
      </div>

      <el-form
        :model="paymentMethod"
        label-position="top"
        ref="paymentMethodContractForm"
      >
        <div class="contract-type mt-3">
          <!-- Spécimen check -->
          <div class="col-form check-image">
            <h4 class="text-danger mb-3">
              {{ $t("PAYMENT_METHODS.CHECK_SPECIMEN") }}
            </h4>
            <el-form-item
              class="form-group-wrapper-item w-full"
              prop="specimen"
              :rules="[
                {
                  required: true,
                  message: $t('PAYMENT_METHODS.specimen_required'),
                },
              ]"
            >
              <el-upload
                ref="uploadRef"
                class="avatar-uploader w-100"
                :auto-upload="false"
                drag
                :action="uploadUrl"
                :on-change="setSpecimen"
                :on-remove="clearSpecimen"
                :data="paymentMethod"
                list-type="picture"
                :show-file-list="false"
                :file-list="fileList"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-upload"></i>
                <div class="el-upload__text">
                  <span v-html="$t('COMMON.DROP_ZONE')"></span>
                </div>
                <div slot="tip" class="el-upload__tip">
                  {{ $t("PAYMENT_METHODS.accepted_files") }}
                </div>
              </el-upload>
            </el-form-item>
          </div>

          <h4 class="text-danger mb-3">{{ $t("CONTRACTS.SELECT") }}</h4>
          <el-form-item
            prop="contract.id"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.CONTRACT_REQUIRED'),
              },
            ]"
          >
            <contract-selection
              :initial-selected="paymentMethod.contract?.id"
              :contract-type="contractType"
              @contract-selected="selectContract"
            />
          </el-form-item>
        </div>

        <div v-if="isUndetermined" class="signers-email-message mt-3">
          <h4 class="text-danger mb-3">
            {{ $t("SIGNATURES.EMAIL_MESSAGE_TITLE") }}
          </h4>
          <!-- SIGNERS_EMAIL_MESSAGE -->
          <el-form-item
            class="form-group-wrapper-item w-100"
            prop="email_message"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              :placeholder="$t('SIGNATURES.SIGNERS_EMAIL_MESSAGE')"
              v-model="emailMessage"
            >
            </el-input>
          </el-form-item>
        </div>
        <div v-else class="credit-and-debit-contract mt-3">
          <div class="row">
            <div class="col-md-6">
              <!-- Sélectionner le contrat credit -->
              <el-form-item
                class="form-group-wrapper-item w-100"
                :label="`${$t('PAYMENT_METHODS.SELECT_CREDIT_CONTRACT')} (*)`"
                prop="creditContract.id"
                :rules="[
                  {
                    required: true,
                    message: $t('PAYMENT_METHODS.CREDIT_CONTRACT_REQUIRED'),
                  },
                ]"
              >
                <contract-selector
                  :contract="paymentMethod.creditContract?.id?.toString()"
                  :filterable="true"
                  :showAll="false"
                  :allowCreate="false"
                  :selectedType="creditContractType"
                  @contractChanged="creditContractSelected"
                />
              </el-form-item>
            </div>
            <div class="col-md-6">
              <!-- Sélectionner le contrat debit -->
              <el-form-item
                class="form-group-wrapper-item w-100"
                :label="`${$t('PAYMENT_METHODS.SELECT_DEBIT_CONTRACT')} (*)`"
                prop="debitContract.id"
                :rules="[
                  {
                    required: true,
                    message: $t('PAYMENT_METHODS.DEBIT_CONTRACT_REQUIRED'),
                  },
                ]"
              >
                <contract-selector
                  :contract="paymentMethod.debitContract?.id?.toString()"
                  :filterable="true"
                  :showAll="false"
                  :allowCreate="false"
                  :selectedType="debitContractType"
                  @contractChanged="debitContractSelected"
                />
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
      <div class="steps-footer">
        <el-button
          @click="goToPaymentMethodForm"
          :loading="loading"
          class="prev"
        >
          <i class="fal fa-arrow-left"></i>
          {{ $t("COMMON.PREVIOUS") }}
        </el-button>

        <el-button
          type="primary"
          class="next btn btn-sm elite-submit"
          native-type="submit"
          @click="checkPaymentMethodInformation"
          :disabled="loading || processing"
          :loading="loading"
        >
          {{
            paymentMethod.id
              ? $t("PAYMENT_METHODS.EDIT_PAYMENT_METHOD")
              : $t("PAYMENT_METHODS.ADD_PAYMENT_METHOD")
          }}
          <i class="fal fa-arrow-right"></i>
        </el-button>
      </div>
    </div>
    <modal
      :show="showPaymentMethodCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("PAYMENT_METHODS.SAME_PAYMENT_METHODS", {
                name: paymentMethod.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="paymentMethods"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('COMMON.EXCERPT')" min-width="300">
                <template v-slot="{ row }">
                  {{ row.excerpt ?? "" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="togglePaymentMethodCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
const apiUrl = process.env.VUE_APP_API_BASE_URL;
import { cloneDeep } from "lodash";
import {
  Option,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Button,
  Divider,
  Step,
  Steps,
  Message,
  Upload,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import {
  DPA_CONTRACT_TYPE,
  DPA_CREDIT_CONTRACT_TYPE,
  DPA_DEBIT_CONTRACT_TYPE,
} from "@/constants/contracts";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import ObjectDetails from "../components/ObjectDetails.vue";
import ContractSelection from "./ContractSelection.vue";
import ContractSelector from "../../ContractManagement/components/ContractSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";
export default {
  layout: "DashboardLayout",
  name: "PaymentMethodForm",

  components: {
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Upload.name]: Upload,
    OrganizationSelector,
    LocationSelector,
    ObjectDetails,
    ContractSelection,
    ContractSelector,
    PhoneNumberInput,
    AddressesSelector,
  },

  mixins: [formMixin],

  props: [
    "paymentMethodData",
    "formErrors",
    "loading",
    "hideSubmit",
    "onlyCharges",
    "holderType",
    "holderId",
  ],
  data() {
    let paymentMethodData = { ...this.paymentMethodData };
    return {
      openContractForm: false,
      selectedOrganizationId: null,
      selectedLocationId: null,
      paymentMethod: paymentMethodData,
      paymentMethods: [],
      total: 0,
      showModal: false,
      showPaymentMethodCheckModal: false,
      processing: false,
      contractType: DPA_CONTRACT_TYPE,
      creditContractType: DPA_CREDIT_CONTRACT_TYPE,
      debitContractType: DPA_DEBIT_CONTRACT_TYPE,
      activeStep: 0,
      emailMessage: null,
      isUndetermined: false,
      imageUrl: null,
      fileList: [],
      uploadUrl: `${apiUrl}/payment-methods/default/specimen`,
      uploadRef: null,
      isInValidImage:false,
    };
  },

  created() {
    this.restoreUploader();
    if (this.paymentMethod.signers.length == 0) {
      this.addSigner();
    }
  },

  mounted() {},

  computed: {},

  methods: {
    beforeAvatarUpload(file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      const isValidType = validImageTypes.includes(file.type);

      // Vérifier la taille du fichier (doit être inférieure à 10 Mo)
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isValidType) {
          this.$notify({
            type: "danger",
            message: this.$t("COMMON.INVALID_IMAGE"),
          });
      }
      if (!isLt2M) {
          this.$notify({
            type: "danger",
            message: this.$t("COMMON.INVALID_IMAGE_SIZE"),
          });
      }
      this.isInValidImage = isValidType && isLt2M;
    },

    restoreUploader() {
      if (this.fileList.length > 0) {
        this.uploadRef.clearFiles();
        this.uploadRef.handleStart(fileList[0]);
      } else {
        this.imageUrl = null;
      }
    },

    clearSpecimen() {
      this.imageUrl = null;
      this.paymentMethod.specimen = null;
    },

    setSpecimen(uploadFile) {
      this.beforeAvatarUpload(uploadFile.raw);
      if (uploadFile.raw && this.isInValidImage) {
        this.fileList = [uploadFile];
        this.paymentMethod.specimen = uploadFile.raw.type;
        this.imageUrl = URL.createObjectURL(uploadFile.raw);
      }else{
        return;
      }
    },

    addressSelected(values) {
      console.log(values);
    },
    creditContractSelected(contractId) {
      this.paymentMethod.creditContract = {
        id: contractId,
        type: "contracts",
      };
    },
    debitContractSelected(contractId) {
      this.paymentMethod.debitContract = {
        id: contractId,
        type: "contracts",
      };
    },
    selectContract(contract) {
      this.paymentMethod.contract.id = contract.id;
      this.isUndetermined = contract.undetermined;
    },

    getSigners(signers) {
      return signers.reduce((acc, signer) => {
        const fullName = `${signer.firstname} ${signer.lastname}`;
        acc[fullName] = signer.email;
        return acc;
      }, {});
    },
    getHolder(holder) {
      let acc = {};
      const fullName = `${holder.firstname} ${holder.lastname}`;
      acc[fullName] = holder.email;
      return acc;
    },
    goToContract() {
      this.$refs["paymentMethodForm"].validate(async (valid) => {
        if (valid) {
          this.openContractForm = true;
          this.activeStep = 1;
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("COMMON.INVALID_FORM"),
          });
        }
      });
    },
    goToPaymentMethodForm() {
      this.openContractForm = false;
      this.activeStep = 0;
    },
    onOrganizationChanged(id) {
      if (id) {
        this.selectedLocationId = null;
        this.paymentMethod.holder_type = "Organization";
        this.paymentMethod.holder_id = id;
      } else {
        this.paymentMethod.holder_type = null;
        this.paymentMethod.holder_id = null;
      }
    },
    onLocationChanged(id) {
      if (id) {
        this.paymentMethod.holder_type = "Location";
        this.paymentMethod.holder_id = id;
      } else if (this.selectedOrganizationId) {
        this.paymentMethod.holder_type = "Organization";
        this.paymentMethod.holder_id = this.selectedOrganizationId;
      }
    },

    feeSelected(selected) {
      this.paymentMethod.charges = [...selected].map((item) => ({
        type: "charges",
        id: item,
      }));
    },
    async handleSubmit() {
      const checkUniquePaymentMethod =
        await this.checkPaymentMethodUniqueInformation();
      if (checkUniquePaymentMethod) {
        await this.sendInformation();
      } else {
        this.showPaymentMethodCheckModal = true;
      }
    },

    async sendInformation() {
      if (this.holderType) {
        this.paymentMethod.holder_type = this.holderType;
        this.paymentMethod.holder_id = this.holderId;
      }
      let paymentMethodData = cloneDeep(this.paymentMethod);
      if (this.emailMessage) {
        paymentMethodData.email_message = this.emailMessage;
      }
      if (this.isUndetermined) {
        delete paymentMethodData.debitContract;
        delete paymentMethodData.creditContract;
      }
      let uploadRef = this.$refs.uploadRef;
      
      this.$emit("paymentMethodSubmitted", paymentMethodData, uploadRef);
    },

    cancelEdition() {
      this.$emit("cancelEdition");
    },

    getPackageIds(charges) {
      if (charges && charges.length) {
        return charges.map((charge) => charge.id);
      }
    },

    // Méthode pour ajouter un signataire
    addSigner() {
      this.paymentMethod.signers.push({
        firstname: "",
        lastname: "",
        address: "",
        phone: "",
        email: "",
      });
    },
    // Méthode pour supprimer un signataire
    removeSigner(index) {
      this.paymentMethod.signers.splice(index, 1);
    },

    async getList() {
      if (!this.paymentMethod?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.paymentMethod?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };
        await this.$store.dispatch("paymentMethods/list", params);
        this.paymentMethods = this.$store.getters["paymentMethods/list"];
        this.total = this.$store.getters["paymentMethods/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkPaymentMethodUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.paymentMethod?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.togglePaymentMethodCheckModalState();
      await this.sendInformation();
    },

    async togglePaymentMethodCheckModalState() {
      this.showPaymentMethodCheckModal = !this.showPaymentMethodCheckModal;
    },

    checkPaymentMethodInformation() {
      this.$refs["paymentMethodContractForm"].validate(async (valid) => {
        if (valid) {
          this.handleSubmit();
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("COMMON.INVALID_FORM"),
          });
        }
      });
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    paymentMethodData(paymentMethodData) {
      if (paymentMethodData) {
        this.paymentMethod = {
          ...this.paymentMethod,
          ...cloneDeep(paymentMethodData),
        };
      }
    },
  },
};
</script>

<style>
.add-form .btn-cancel {
  font-size: 14px;
  min-height: 40px;
  border-radius: 8px;
  padding: 0 15px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.add-form .el-input__inner,
.add-form .el-textarea {
  background-color: transparent;
  border-radius: 8px;
  color: #000;
}
.form-group-wrapper-item .el-select {
  width: 100%;
}
.form-group-wrapper-item label {
  margin-bottom: 0.1rem !important;
}
.form-group-wrapper-item .el-form-item__label {
  padding: 0 0 1px !important;
}

.el-upload.el-upload--picture {
  width: 100% !important;
  height: 100% !important;
}

.el-upload-dragger .avatar {
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  width: auto !important;
  height: 100% !important;
}

.el-upload-dragger {
  padding: 10px 10px 20px 10px !important;
  width: 100% !important;
  height: 100% !important;
}
</style>
