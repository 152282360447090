<template>
  <div>
    <div class="page-wrapper-table payment-methods mt-3">
      <div v-if="loading" class="table-loading" slot="empty">
        <img src="/img/loading.gif" />
      </div>
      <div v-if="!loading" class="page-data">
        <div class="page-wrapper-table-header">
          <base-input>
            <el-select
              :label="$t('COMMON.STATUS')"
              :placeholder="$t('COMMON.STATUS')"
              v-model="selectedStatus"
              @change="
                (status) => {
                  selectedStatus = status;
                }
              "
            >
              <el-option
                v-for="(signature, val) in SIGNATURE_STATUS"
                :key="val"
                :value="val"
                :label="`${val ? $t('SIGNATURES.' + val) : ''}`"
              />
            </el-select>
          </base-input>

          <base-input
            v-model="query"
            type="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <base-button
            class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
            icon
            size="sm"
            @click="resetFilters"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-undo"></i>
            </span>
          </base-button>
        </div>
        <div class="payment-methods-list-grid-body">
          <div class="payment-methods-list-grid-body-inner">
            <div class="list-grid">
              <!-- Section "Par défaut" -->
              <div class="default-method" v-if="waitingSignature">
                <h5>{{ $t("SIGNATURES.SENTS") }}</h5>
                <signature-card
                  :signature="waitingSignature"
                  @deleteSignature="deleteSignature"
                  @editSignature="editSignature"
                  @resendEmail="resendEmail"
                />
              </div>

              <!-- Section "Autres" -->
              <div class="other-methods mt-4" v-if="otherSignatures.length">
                <h5>{{ $t("SIGNATURES.OTHERS") }}</h5>
                <signature-card
                  v-for="(method, index) in otherSignatures"
                  :key="index"
                  :signature="method"
                  @deleteSignature="deleteSignature"
                  @editSignature="editSignature"
                  @resendEmail="resendEmail"
                />
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="page-wrapper-table-footer">
          <p class="card-category">
            {{
              $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
                from: total ? from + 1 : 0,
                to: to,
                of: total,
              })
            }}
            <span v-if="selectedRows.length">
              &nbsp; &nbsp;
              {{
                $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length })
              }}
            </span>
          </p>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import { SIGNATURE_STATUS } from "@/constants/signatures";
import { STATUS_SENT } from "@/constants/signatures";
import SignatureCard from "../components/SignatureCard.vue";

export default {
  name: "SignatureListGridView",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    SignatureCard,
  },

  mixins: [requestErrorMixin],

  props: ["objectType", "objectId"],

  data() {
    return {
      selectedStatus: null,
      SIGNATURE_STATUS: SIGNATURE_STATUS,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      signatures: [],
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    // Renvoie le moyen de paiement par défaut
    waitingSignature() {
      return this.signatures.find(
        (signature) => signature.status == STATUS_SENT
      );
    },
    // Renvoie les autres moyens de paiement
    otherSignatures() {
      return this.signatures.filter(
        (signature) => signature.status != STATUS_SENT
      );
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    resetFilters() {
      this.query = null;
      this.selectedStatus = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
            ...(this.objectType ? { object_type: this.objectType } : {}),
            ...(this.objectId ? { object_id: this.objectId } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };

        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }

        await this.$store.dispatch("signatures/list", params);
        this.signatures = this.$store.getters["signatures/list"];
        this.total = this.$store.getters["signatures/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editSignature(signature) {
      this.$emit("onEditSignature", signature);
    },

    async deleteSignature(id) {
      const confirmation = await swal.fire({
        text: this.$t("SIGNATURES.DELETE_THIS_SIGNATURE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("signatures/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("SIGNATURES.SIGNATURE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async resendEmail(signature) {
      const confirmation = await swal.fire({
        text: this.$t("SIGNATURES.SEND_FOR_SIGN_MESSAGE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("SIGNATURES.SEND"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          this.loading = true;

          await this.$store.dispatch("signatures/send", signature);

          this.$notify({
            type: "success",
            message: this.$t("SIGNATURES.SIGNATURE_SENT"),
          });
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
